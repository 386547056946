import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">



<path d="M1482 2048 c-16 -16 -15 -45 3 -55 12 -7 12 -20 -3 -96 -10 -48 -23
-92 -30 -99 -19 -19 -40 8 -61 79 -16 52 -17 69 -8 84 9 15 8 22 -5 35 -9 9
-24 14 -32 11 -21 -8 -21 -57 -1 -57 12 0 15 -14 15 -69 0 -85 -12 -121 -40
-121 -25 0 -62 57 -57 87 2 13 -2 31 -9 40 -12 17 -14 17 -31 0 -17 -18 -17
-19 2 -35 30 -24 37 -47 44 -132 l6 -75 47 -23 c99 -49 269 -49 368 1 44 23
45 24 47 78 5 89 13 118 41 144 25 23 25 26 10 42 -14 13 -20 14 -33 3 -9 -7
-13 -21 -10 -31 8 -26 -23 -87 -48 -95 -33 -11 -47 20 -47 107 0 59 3 78 15
83 8 3 15 14 15 25 0 21 -22 33 -45 25 -17 -7 -20 -35 -6 -49 10 -11 -28 -138
-47 -157 -22 -22 -42 15 -57 101 -13 73 -13 84 0 98 20 20 19 40 -4 53 -24 13
-24 13 -39 -2z m75 -282 c7 -4 22 -2 34 5 17 9 25 8 37 -5 30 -29 2 -37 -128
-34 -122 3 -139 8 -120 38 7 12 13 12 33 1 26 -14 52 -6 77 24 12 14 15 14 34
-3 12 -11 27 -22 33 -26z m-168 -56 c116 -22 278 -4 323 36 15 14 20 15 16 4
-15 -48 -171 -80 -301 -61 -80 11 -136 34 -145 60 -3 8 6 5 24 -8 16 -11 53
-25 83 -31z m61 -71 c0 -12 -7 -19 -20 -19 -19 0 -28 28 -14 43 11 11 34 -5
34 -24z m88 -1 c3 -22 -2 -29 -22 -34 -32 -8 -46 1 -46 30 0 30 9 38 40 34 19
-2 26 -10 28 -30z m62 1 c0 -12 -7 -19 -19 -19 -26 0 -36 17 -21 35 15 18 40
8 40 -16z"/>
<path d="M1220 1624 c0 -9 15 -31 34 -49 36 -34 110 -59 206 -70 121 -14 285
36 316 97 22 42 10 46 -26 9 -44 -44 -123 -65 -245 -65 -119 -1 -198 20 -245
64 -37 35 -40 36 -40 14z"/>
<path d="M450 1350 c0 -23 4 -30 20 -30 19 0 20 -7 20 -106 0 -101 -1 -106
-20 -101 -17 4 -20 0 -20 -24 l0 -29 81 0 80 0 -3 28 c-2 19 -9 27 -22 27 -16
0 -20 7 -19 35 l0 35 46 -3 47 -4 0 34 c0 33 0 33 -46 33 l-46 0 -3 38 c-2 37
-2 37 37 37 34 0 38 -3 38 -25 0 -21 5 -25 30 -25 30 0 30 1 30 55 l0 55 -125
0 -125 0 0 -30z m108 -140 c-2 -8 -11 -16 -21 -18 -14 -3 -17 3 -15 26 2 26 4
28 20 18 10 -6 17 -18 16 -26z"/>
<path d="M793 1360 c-45 -27 -63 -60 -70 -123 -12 -115 68 -198 175 -183 87
11 137 72 136 166 0 67 -18 107 -63 137 -45 30 -132 32 -178 3z m132 -65 c21
-20 25 -34 25 -80 0 -67 -22 -95 -75 -95 -46 0 -67 32 -67 102 0 44 4 56 26
75 35 30 61 29 91 -2z"/>
<path d="M1070 1350 c0 -23 4 -30 20 -30 19 0 20 -7 20 -106 0 -101 -1 -106
-20 -101 -17 4 -20 0 -20 -24 l0 -29 75 0 75 0 0 30 c0 21 -4 28 -14 24 -12
-5 -15 13 -18 100 -3 96 -1 106 15 106 12 0 17 8 17 30 l0 30 -75 0 -75 0 0
-30z"/>
<path d="M1250 1325 c0 -54 0 -55 30 -55 25 0 30 4 30 25 0 20 5 25 25 25 l25
0 0 -105 c0 -98 -1 -105 -20 -105 -15 0 -20 -7 -20 -25 0 -24 2 -25 75 -25
l75 0 0 30 c0 22 -4 28 -15 24 -13 -5 -15 10 -15 100 l0 106 25 0 c20 0 25 -5
25 -25 0 -21 5 -25 30 -25 30 0 30 1 30 55 l0 55 -150 0 -150 0 0 -55z m163
-5 c-4 -17 -23 -29 -23 -16 0 2 -3 11 -6 20 -4 11 0 16 14 16 15 0 19 -5 15
-20z"/>
<path d="M1570 1350 c0 -23 4 -30 20 -30 19 0 20 -7 20 -105 0 -95 -2 -106
-17 -103 -13 2 -19 -5 -21 -24 l-3 -28 130 0 131 0 0 55 c0 54 0 55 -30 55
-25 0 -30 -4 -30 -25 0 -22 -4 -25 -40 -25 -39 0 -40 1 -40 35 0 35 0 35 45
35 43 0 45 1 45 30 0 29 -2 30 -45 30 -45 0 -45 0 -45 35 0 34 1 35 40 35 36
0 40 -2 40 -25 0 -21 5 -25 30 -25 30 0 30 1 30 55 l0 55 -130 0 -130 0 0 -30z
m109 -119 c8 -14 7 -22 -4 -31 -17 -14 -28 -8 -31 17 -6 35 19 45 35 14z"/>
<path d="M1941 1362 c-92 -47 -97 -221 -8 -286 34 -25 130 -28 171 -6 23 12
56 56 56 74 0 2 -15 9 -32 15 -30 11 -34 9 -51 -14 -13 -18 -28 -25 -51 -25
-46 0 -68 29 -74 94 -4 48 -1 57 21 80 15 14 37 26 51 26 27 0 56 -23 56 -45
0 -10 8 -11 40 -3 22 6 40 14 40 19 0 23 -27 62 -51 75 -39 19 -127 18 -168
-4z"/>
<path d="M2190 1350 c0 -22 5 -30 18 -30 15 0 17 -11 15 -102 -2 -86 -4 -103
-17 -102 -11 1 -16 -7 -16 -27 l0 -29 75 0 75 0 0 29 c0 24 -3 28 -20 24 -18
-5 -20 0 -20 36 l0 41 70 0 70 0 0 -41 c0 -36 -2 -41 -20 -36 -17 4 -20 0 -20
-24 l0 -29 75 0 75 0 0 29 c0 24 -3 28 -20 24 -19 -5 -20 0 -20 101 0 99 1
106 20 106 16 0 20 7 20 30 l0 30 -75 0 -75 0 0 -30 c0 -23 4 -30 20 -30 16 0
20 -7 20 -35 l0 -35 -70 0 -70 0 0 35 c0 28 4 35 20 35 16 0 20 7 20 30 l0 30
-75 0 -75 0 0 -30z m110 -128 c0 -10 -22 -32 -32 -32 -4 0 -8 14 -8 30 0 28 2
30 20 20 11 -6 20 -14 20 -18z m172 -24 c-5 -5 -13 -3 -21 6 -9 11 -8 18 6 32
17 17 18 17 21 -5 2 -13 0 -27 -6 -33z"/>
<path d="M597 993 c-9 -14 -1 -47 12 -51 8 -3 11 5 9 22 -4 29 -13 41 -21 29z"/>
<path d="M680 985 c6 -8 9 -19 6 -24 -4 -5 -2 -12 3 -15 5 -3 11 8 13 24 4 25
1 30 -15 30 -16 0 -17 -3 -7 -15z"/>
<path d="M1014 985 c-4 -8 -4 -22 0 -30 6 -17 36 -21 36 -5 0 6 -5 9 -11 8 -6
-2 -14 3 -16 10 -3 6 3 12 12 12 9 0 13 5 10 10 -9 15 -25 12 -31 -5z"/>
<path d="M1121 990 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"/>
<path d="M1400 970 c0 -22 5 -30 18 -30 14 0 15 2 2 10 -13 9 -13 10 0 10 13
0 13 1 0 10 -10 6 -11 10 -2 10 6 0 12 5 12 10 0 6 -7 10 -15 10 -10 0 -15
-10 -15 -30z"/>
<path d="M1490 985 c-10 -12 -10 -18 0 -30 14 -17 40 -20 40 -5 0 5 -7 7 -15
4 -9 -4 -15 0 -15 10 0 9 7 16 16 16 8 0 12 5 9 10 -9 14 -21 12 -35 -5z"/>
<path d="M1588 970 c2 -16 8 -30 12 -30 4 0 10 14 12 30 4 23 1 30 -12 30 -13
0 -16 -7 -12 -30z"/>
<path d="M1670 970 c0 -22 5 -30 18 -30 10 0 13 3 7 8 -12 8 -13 22 -2 40 5 8
3 12 -7 12 -11 0 -16 -9 -16 -30z"/>
<path d="M1750 970 c0 -23 5 -30 19 -30 23 0 35 28 20 46 -20 24 -39 16 -39
-16z"/>
<path d="M1931 977 c-14 -36 -14 -44 1 -29 8 8 15 9 25 1 17 -15 16 -1 -2 31
l-13 25 -11 -28z"/>
<path d="M2120 989 c0 -8 6 -24 14 -35 14 -18 15 -18 21 9 4 15 5 31 1 34 -3
4 -6 -2 -6 -13 -1 -19 -1 -19 -15 0 -11 15 -15 16 -15 5z"/>
<path d="M766 974 c-11 -27 -7 -37 9 -24 12 10 15 10 15 0 0 -8 3 -11 6 -7 8
7 -5 47 -16 47 -4 0 -11 -7 -14 -16z"/>
<path d="M1210 964 c0 -15 4 -23 10 -19 6 4 8 12 4 18 -3 7 2 3 10 -7 16 -19
16 -19 16 5 0 18 -6 25 -20 26 -15 1 -20 -4 -20 -23z"/>
<path d="M1311 964 c0 -16 3 -23 6 -16 4 10 8 10 19 1 11 -9 14 -6 13 17 0 16
-3 23 -6 16 -4 -10 -8 -10 -19 -1 -11 9 -14 6 -13 -17z"/>
<path d="M2216 974 c-11 -27 -7 -37 9 -24 12 10 15 10 15 0 0 -8 3 -11 6 -7 8
7 -5 47 -16 47 -4 0 -11 -7 -14 -16z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
